import { Box, Heading, Text, Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

function NotFound() {
  const navigate = useNavigate();

  const navigateToHomeHandler = () => {
    navigate('/');
  };

  return (
    <Box textAlign="center" minH="100vh" py={20} px={6}>
      <Heading
        display="inline-block"
        as="h2"
        size="2xl"
        bgGradient="linear(to-r, red.500, red.900)"
        backgroundClip="text"
      >
        404
      </Heading>
      <Text fontSize="18px" fontWeight={500} mt={3} mb={2}>
        Page Not Found
      </Text>

      <Text color="gray.500" mb={6}>
        Oops! It seems like the page you&lsquo;re looking for doesn&lsquo;t
        exist.
      </Text>

      <Button
        colorScheme="red"
        bgGradient="linear(to-r, red.500, red.900)"
        color="white"
        variant="solid"
        onClick={navigateToHomeHandler}
      >
        Home
      </Button>
    </Box>
  );
}

export default NotFound;

import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useToast } from '@chakra-ui/react';

import { ReducerStatus } from 'utils/constants';

const useToastNotif = () => {
  const toast = useToast();
  const toastIdRef = useRef();

  const updateQuestOrderNo = useSelector(
    (state) => state.quest.updateQuestOrderNo
  );

  const updateSubQuestOrderNo = useSelector(
    (state) => state.subquest.updateSubQuestOrderNo
  );

  useEffect(() => {
    if (
      updateSubQuestOrderNo.status === ReducerStatus.LOADING ||
      updateQuestOrderNo.status === ReducerStatus.LOADING
    ) {
      toastIdRef.current = toast({
        title: 'Saving',
        description: 'Please wait',
        status: 'loading',
        duration: 9000,
        isClosable: false,
        position: 'top-right',
      });
    } else if (
      updateSubQuestOrderNo.status === ReducerStatus.SUCCEEDED ||
      updateQuestOrderNo.status === ReducerStatus.SUCCEEDED
    ) {
      toast.update(toastIdRef.current, {
        title: 'Saved',
        description: 'Order number has been updated.',
        status: 'success',
        isClosable: true,
      });
    } else if (
      updateSubQuestOrderNo.status === ReducerStatus.FAILED ||
      updateQuestOrderNo.status === ReducerStatus.FAILED
    ) {
      toast.update(toastIdRef.current, {
        title: 'Failed',
        description: 'Something went wrong with the request.',
        status: 'error',
        isClosable: true,
      });
    }
  }, [updateQuestOrderNo, updateSubQuestOrderNo, toast]);

  return () => {};
};

export default useToastNotif;

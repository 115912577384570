import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import {
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Flex,
  Text,
  Box,
  Divider,
  Textarea,
  Center,
  VStack,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Progress,
} from '@chakra-ui/react';

import { MdVideoLibrary } from 'react-icons/md';
import SubQuestForm from 'components/subQuest/SubQuestForm';
import FormikFormControl from 'components/common/forms/FormikFormControl';

import useFileInput from 'hooks/useFileInput';
import { AttachmentIcon, CloseIcon } from '@chakra-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { ReducerStatus } from 'utils/constants';
import ResultModal from 'components/common/modals/ResultModal';

import { createVideoSubQuest } from 'store/modules/subquest/subquest-action';
import { subquestActions } from 'store/modules/subquest/subquest-slice';
import { arrayOfString } from 'utils/jsonParser';

function CreateVideoMd({ quest }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const dispatch = useDispatch();

  const [triggerSubQuestForm, setTriggerSubQuestForm] = useState(0);
  const [isSubQuestFormValid, setIsSubQuestFormValid] = useState(false);
  const [isFormSubmit, setIsFormSubmit] = useState(false);
  const [subQuestFormInputs, setSubQuestFormInputs] = useState({
    name: '',
    objective: '',
    code: '',
    isPublished: '',
    tags: [],
    groups: [],
  });

  const [formInputs, setFormInputs] = useState({
    transcript: '',
  });

  const createVideo = useSelector((state) => state.subquest.createVideo);

  const uploadProgress = useSelector((state) => state.subquest.uploadProgress);

  const formikForm = useRef();

  const {
    file,
    fileURL,
    fileError,
    fileInputRef,
    setFileError,
    handleAttachFile,
    handleRemoveSelectedFile,
    handleFileInputChange,
  } = useFileInput({ maxFileSizeMb: 500, fileType: 'video/mp4' });

  useEffect(() => {
    if (file.name) setFileError('');
  }, [file, setFileError]);

  useEffect(() => {
    if (quest.ownerGroups) {
      setSubQuestFormInputs((prev) => ({
        ...prev,
        groups: arrayOfString(quest.ownerGroups),
      }));
    }
  }, [quest.ownerGroups]);

  const formValidation = Yup.object({
    transcript: Yup.string().min(3).required('Required'),
  });

  const handleTriggerSubmit = () => {
    setTriggerSubQuestForm((trigger) => trigger + 1);
    if (!file.name) setFileError('Video is required');
    setIsFormSubmit(true);
  };

  const handleSubmitForm = (values) => {
    if (isSubQuestFormValid === false || !file.name) return;

    setFormInputs({ ...values });

    const formData = new FormData();

    Object.entries(subQuestFormInputs).forEach(([key, value]) => {
      if (key !== 'tags' && key !== 'groups') formData.append(key, value);
    });

    if (subQuestFormInputs.tags.length > 0)
      subQuestFormInputs.tags.forEach((tag) => formData.append('Tags', tag));
    if (subQuestFormInputs.groups.length > 0)
      subQuestFormInputs.groups.forEach((group) =>
        formData.append('OwnerGroups', group)
      );

    Object.entries(values).forEach(([key, value]) => {
      formData.append(key, value);
    });

    formData.append('Video', file, file.name);

    dispatch(createVideoSubQuest({ id: quest.id, formData }));
  };

  useEffect(() => {
    if (isFormSubmit && isSubQuestFormValid) {
      formikForm?.current?.submitForm();
      setIsFormSubmit(false);
    }
  }, [isFormSubmit, isSubQuestFormValid, setIsFormSubmit]);

  const handleResult = () => {
    if (createVideo.status === ReducerStatus.SUCCEEDED) {
      setFormInputs({
        transcript: '',
      });

      setSubQuestFormInputs({
        name: '',
        objective: '',
        code: '',
        isPublished: '',
        tags: [],
        emails: [],
      });

      setTriggerSubQuestForm(-1);
      handleRemoveSelectedFile();
      onClose();
    }
    dispatch(subquestActions.resetCreateInteractiveVideo());
  };

  return (
    <Box>
      {createVideo.status === ReducerStatus.SUCCEEDED ||
      createVideo.status === ReducerStatus.FAILED ? (
        <ResultModal
          isOpen={isOpen}
          onSubmit={handleResult}
          isError={createVideo.status === ReducerStatus.FAILED}
          title={
            createVideo.status === ReducerStatus.SUCCEEDED
              ? 'Video Sub-Quest Created'
              : null
          }
          message={
            createVideo.status === ReducerStatus.SUCCEEDED
              ? 'The video sub-quest has been created successfully.'
              : createVideo.error?.data?.title || 'Server Error'
          }
          errorObject={createVideo.error?.data?.errors}
        />
      ) : (
        <>
          <Button
            onClick={onOpen}
            leftIcon={<MdVideoLibrary />}
            w="100%"
            colorScheme="red"
            size="lg"
          >
            Video
          </Button>
          <Modal
            size="2xl"
            closeOnOverlayClick={createVideo.status !== ReducerStatus.LOADING}
            isOpen={isOpen}
            onClose={onClose}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Create Video Sub-Quest</ModalHeader>
              <ModalCloseButton
                disabled={createVideo.status === ReducerStatus.LOADING}
              />
              <ModalBody my={3}>
                <SubQuestForm
                  questName={quest.name}
                  groups={quest.ownerGroups}
                  subQuestFormInputs={subQuestFormInputs}
                  triggerSubQuestForm={triggerSubQuestForm}
                  setSubQuestFormInputs={setSubQuestFormInputs}
                  setIsSubQuestFormValid={setIsSubQuestFormValid}
                />
              </ModalBody>
              <Formik
                enableReinitialize
                innerRef={formikForm}
                initialValues={formInputs}
                onSubmit={(values) => {
                  handleSubmitForm(values);
                }}
                validationSchema={formValidation}
              >
                {() => (
                  <Box>
                    <Form>
                      <ModalBody>
                        <Divider mb={4} />

                        <VStack spacing={6} align="stretch">
                          <FormControl isInvalid={fileError !== ''}>
                            {file?.name && (
                              <Box my={4}>
                                <Center>
                                  <video controls id="video-tag">
                                    <track kind="captions" />
                                    <source id="video-source" src={fileURL} />
                                    Your browser does not support the video tag.
                                  </video>
                                </Center>

                                <Flex
                                  gap={2}
                                  my={2}
                                  justify="center"
                                  align="center"
                                >
                                  <Text flex={1}>
                                    Selected File: {file?.name}
                                  </Text>
                                  <Button
                                    type="button"
                                    colorScheme="teal"
                                    variant="ghost"
                                    onClick={handleRemoveSelectedFile}
                                  >
                                    <CloseIcon />
                                  </Button>
                                </Flex>
                              </Box>
                            )}

                            <Box
                              as="button"
                              type="button"
                              borderRadius="md"
                              bg="teal"
                              w="100%"
                              mt={5}
                              px={4}
                              h={10}
                              color="white"
                              onClick={handleAttachFile}
                            >
                              <input
                                type="file"
                                hidden
                                accept="video/mp4"
                                ref={fileInputRef}
                                onChange={handleFileInputChange}
                              />
                              <AttachmentIcon mx={2} />
                              Attach Video File
                            </Box>
                            {fileError !== '' && (
                              <FormErrorMessage>{fileError}</FormErrorMessage>
                            )}
                            <FormHelperText>
                              Attach a video (.mp4) that will be presented in
                              this sub-quest. File type should be a video, and
                              the size should not exceed 500MB.
                            </FormHelperText>
                          </FormControl>

                          <Field name="transcript">
                            {({ field, form }) => (
                              <FormikFormControl
                                label="Transcript"
                                helperText="Please provide the transcript of the video. The transcript will be utilized to train the AI Companion, enabling it to respond to trainee questions that are relevant to the content covered in the video."
                                errors={form.errors.transcript}
                                touched={form.touched.transcript}
                              >
                                <Textarea {...field} />
                              </FormikFormControl>
                            )}
                          </Field>

                          {createVideo.status === ReducerStatus.LOADING && (
                            <Progress
                              hasStripe
                              isAnimated
                              value={uploadProgress}
                            />
                          )}
                        </VStack>
                      </ModalBody>

                      <ModalFooter>
                        <Button
                          variant="outline"
                          colorScheme="blue"
                          mr={3}
                          onClick={onClose}
                          isLoading={
                            createVideo.status === ReducerStatus.LOADING
                          }
                        >
                          Close
                        </Button>
                        <Button
                          isLoading={
                            createVideo.status === ReducerStatus.LOADING
                          }
                          onClick={handleTriggerSubmit}
                          colorScheme="red"
                          px={10}
                        >
                          Submit
                        </Button>
                      </ModalFooter>
                    </Form>
                  </Box>
                )}
              </Formik>
            </ModalContent>
          </Modal>
        </>
      )}
    </Box>
  );
}

CreateVideoMd.propTypes = {
  quest: PropTypes.object.isRequired,
};

export default CreateVideoMd;

import { useEffect, useRef, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Flex,
  Text,
  Box,
  Divider,
  Textarea,
  VStack,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Progress,
  Center,
} from '@chakra-ui/react';

import SubQuestForm from 'components/subQuest/SubQuestForm';
import FormikFormControl from 'components/common/forms/FormikFormControl';

import useFileInput from 'hooks/useFileInput';
import { AttachmentIcon, CloseIcon } from '@chakra-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { ReducerStatus } from 'utils/constants';
import ResultModal from 'components/common/modals/ResultModal';

import { updateVideoSubQuest } from 'store/modules/subquest/subquest-action';
import { subquestActions } from 'store/modules/subquest/subquest-slice';
import { arrayOfString } from 'utils/jsonParser';

function UpdateVideoMd() {
  const dispatch = useDispatch();

  const [triggerSubQuestForm, setTriggerSubQuestForm] = useState(0);
  const [isSubQuestFormValid, setIsSubQuestFormValid] = useState(false);
  const [isFormSubmit, setIsFormSubmit] = useState(false);
  const [subQuestFormInputs, setSubQuestFormInputs] = useState({
    name: '',
    objective: '',
    code: '',
    isPublished: '',
    tags: [],
    groups: [],
  });

  const [formInputs, setFormInputs] = useState({
    transcript: '',
  });

  const subQuest = useSelector((state) => state.subquest.subQuest);
  const questId = useSelector((state) => state.subquest.questId);
  const updateVideo = useSelector((state) => state.subquest.updateVideo);

  const uploadProgress = useSelector((state) => state.subquest.uploadProgress);

  const formikForm = useRef();
  const videoRef = useRef();

  const {
    file,
    fileURL,
    fileError,
    fileInputRef,
    setFileError,
    handleAttachFile,
    handleRemoveSelectedFile,
    handleFileInputChange,
  } = useFileInput({ maxFileSizeMb: 500, fileType: 'video/mp4' });

  useEffect(() => {
    if (subQuest && updateVideo.modal) {
      const { name, objective, code, isPublished, tags, ownerGroups } =
        subQuest;
      const { transcript } = subQuest.subQuestVideo;

      setIsSubQuestFormValid(false);
      setSubQuestFormInputs({
        name,
        objective,
        code,
        isPublished: isPublished.toString(),
        tags: arrayOfString(tags),
        groups: arrayOfString(ownerGroups),
      });

      setFormInputs({
        transcript,
      });
    }
  }, [
    subQuest,
    updateVideo.modal,
    setSubQuestFormInputs,
    setFormInputs,
    setIsSubQuestFormValid,
  ]);

  useEffect(() => {
    videoRef.current?.load();
  }, [file]);

  useEffect(() => {
    if (file.name) setFileError('');
  }, [file, setFileError]);

  const formValidation = Yup.object({
    transcript: Yup.string().min(3).required('Required'),
  });

  const handleTriggerSubmit = () => {
    setTriggerSubQuestForm((trigger) => trigger + 1);
    if (!file.name) setFileError('Video is required');
    setIsFormSubmit(true);
  };

  const handleSubmitForm = (values) => {
    if (isSubQuestFormValid === false) return;

    setFormInputs({ ...values });
    const formData = new FormData();

    Object.entries(subQuestFormInputs).forEach(([key, value]) => {
      if (key !== 'tags' && key !== 'groups') formData.append(key, value);
    });

    if (subQuestFormInputs.tags.length > 0)
      subQuestFormInputs.tags.forEach((tag) => formData.append('Tags', tag));
    if (subQuestFormInputs.groups.length > 0)
      subQuestFormInputs.groups.forEach((group) =>
        formData.append('OwnerGroups', group)
      );

    Object.entries(values).forEach(([key, value]) => {
      formData.append(key, value);
    });

    if (file.name) {
      formData.append('Video', file, file.name);
      formData.append('IsRemoveVideo', 'true');
    }

    dispatch(
      updateVideoSubQuest({
        subQuestId: subQuest?.id,
        questId,
        formData,
      })
    );
  };

  useEffect(() => {
    if (isFormSubmit && isSubQuestFormValid) {
      formikForm?.current?.submitForm();
      setIsFormSubmit(false);
    }
  }, [isFormSubmit, isSubQuestFormValid, setIsFormSubmit]);

  const handleToggleUpdateModal = (isOpen) => {
    dispatch(subquestActions.toggleVideoUpdateModal(isOpen));
  };

  const handleResult = () => {
    if (updateVideo.status === ReducerStatus.SUCCEEDED) {
      setFormInputs({
        transcript: '',
      });

      setSubQuestFormInputs({
        name: '',
        objective: '',
        code: '',
        isPublished: '',
        tags: [],
        emails: [],
      });

      setTriggerSubQuestForm(0);
      setIsSubQuestFormValid(false);
      handleRemoveSelectedFile();
      handleToggleUpdateModal(true);
    }
    dispatch(subquestActions.resetVideoUpdate());
  };

  return (
    <Box>
      {updateVideo.status === ReducerStatus.SUCCEEDED ||
      updateVideo.status === ReducerStatus.FAILED ? (
        <ResultModal
          isOpen={updateVideo.modal}
          onSubmit={handleResult}
          isError={updateVideo.status === ReducerStatus.FAILED}
          title={
            updateVideo.status === ReducerStatus.SUCCEEDED
              ? 'Video Sub-Quest Updated'
              : null
          }
          message={
            updateVideo.status === ReducerStatus.SUCCEEDED
              ? 'The video sub-quest has been updated successfully.'
              : updateVideo.error?.data?.title || 'Server Error'
          }
          errorObject={updateVideo.error?.data?.errors}
        />
      ) : (
        <Modal
          size="2xl"
          isOpen={updateVideo.modal}
          closeOnOverlayClick={updateVideo.status !== ReducerStatus.LOADING}
          onClose={() => {
            handleToggleUpdateModal(false);
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Update Video Sub-Quest</ModalHeader>
            <ModalCloseButton
              disabled={updateVideo.status === ReducerStatus.LOADING}
            />
            <ModalBody my={3}>
              <SubQuestForm
                subQuestFormInputs={subQuestFormInputs}
                triggerSubQuestForm={triggerSubQuestForm}
                setSubQuestFormInputs={setSubQuestFormInputs}
                setIsSubQuestFormValid={setIsSubQuestFormValid}
              />
            </ModalBody>
            <Formik
              enableReinitialize
              innerRef={formikForm}
              initialValues={formInputs}
              onSubmit={(values) => {
                handleSubmitForm(values);
              }}
              validationSchema={formValidation}
            >
              {() => (
                <Box>
                  <Form>
                    <ModalBody>
                      <Divider mb={4} />

                      <VStack spacing={6} align="stretch">
                        <Center>
                          <video controls id="video-tag" ref={videoRef}>
                            <track kind="captions" />
                            <source
                              id="video-source"
                              src={
                                file?.name
                                  ? fileURL
                                  : `${process.env.REACT_APP_FILE_URL}${subQuest.subQuestVideo.video}`
                              }
                            />
                            Your browser does not support the video tag.
                          </video>
                        </Center>

                        <FormControl isInvalid={fileError !== ''}>
                          {file.name && (
                            <Box my={4}>
                              <Flex
                                gap={2}
                                my={2}
                                justify="center"
                                align="center"
                              >
                                <Text flex={1}>
                                  Selected File: {file?.name}
                                </Text>
                                <Button
                                  type="button"
                                  colorScheme="teal"
                                  variant="ghost"
                                  onClick={handleRemoveSelectedFile}
                                >
                                  <CloseIcon />
                                </Button>
                              </Flex>
                            </Box>
                          )}

                          <Box
                            as="button"
                            type="button"
                            borderRadius="md"
                            bg="teal"
                            w="100%"
                            px={4}
                            mt={5}
                            h={10}
                            color="white"
                            onClick={handleAttachFile}
                          >
                            <input
                              type="file"
                              hidden
                              accept="video/mp4"
                              ref={fileInputRef}
                              onChange={handleFileInputChange}
                            />
                            <AttachmentIcon mx={2} />
                            Attach Interactive Video File
                          </Box>
                          {fileError !== '' && (
                            <FormErrorMessage>{fileError}</FormErrorMessage>
                          )}
                          {!file.name && (
                            <Text mt={2}>
                              Current File: &nbsp;
                              {subQuest.subQuestVideo.video}
                            </Text>
                          )}

                          <FormHelperText>
                            Attach a video (.mp4) that will be presented in this
                            sub-quest. File type should be a video, and the size
                            should not exceed 500MB.
                          </FormHelperText>
                        </FormControl>

                        <Field name="transcript">
                          {({ field, form }) => (
                            <FormikFormControl
                              label="Transcript"
                              helperText="Please provide the transcript of the video. The transcript will be utilized to train the AI Companion, enabling it to respond to trainee questions that are relevant to the content covered in the video."
                              errors={form.errors.transcript}
                              touched={form.touched.transcript}
                            >
                              <Textarea {...field} />
                            </FormikFormControl>
                          )}
                        </Field>

                        {updateVideo.status === ReducerStatus.LOADING && (
                          <Progress
                            hasStripe
                            isAnimated
                            value={uploadProgress}
                          />
                        )}
                      </VStack>
                    </ModalBody>

                    <ModalFooter>
                      <Button
                        variant="outline"
                        colorScheme="blue"
                        mr={3}
                        isLoading={updateVideo.status === ReducerStatus.LOADING}
                        onClick={() => {
                          handleToggleUpdateModal(false);
                        }}
                      >
                        Close
                      </Button>
                      <Button
                        isLoading={updateVideo.status === ReducerStatus.LOADING}
                        onClick={handleTriggerSubmit}
                        colorScheme="red"
                        px={10}
                      >
                        Submit
                      </Button>
                    </ModalFooter>
                  </Form>
                </Box>
              )}
            </Formik>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
}

export default UpdateVideoMd;

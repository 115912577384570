import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  useColorModeValue,
  Text,
  Box,
  IconButton,
  Flex,
  Collapse,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Stack,
  Skeleton,
} from '@chakra-ui/react';

import {
  DragHandleIcon,
  ArrowDownIcon,
  ArrowUpIcon,
  CheckCircleIcon,
  EditIcon,
  DeleteIcon,
} from '@chakra-ui/icons';

import { RiDraftFill } from 'react-icons/ri';
import { HiDotsVertical } from 'react-icons/hi';
import { GrDetach } from 'react-icons/gr';

import { DndContext, closestCorners } from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
  useSortable,
  arrayMove,
} from '@dnd-kit/sortable';

import { CSS } from '@dnd-kit/utilities';

import { questActions } from 'store/modules/quest/quest-slice';
import { subquestActions } from 'store/modules/subquest/subquest-slice';
import { fetchQuestByIdAction } from 'store/modules/quest/quest-actions';
import {
  getSubQuestsByQuestId,
  updateSubQuestOrderNo,
} from 'store/modules/subquest/subquest-action';
import { ReducerStatus } from 'utils/constants';

import AddSubQuestMd from 'components/subQuest/AddSubQuestMd';
import SubQuestAdminItem from './SubQuestAdminItem';

function QuestAdminItem({ courseId, id, courseQuest }) {
  const dispatch = useDispatch();
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };

  const [isToggled, setIsToggled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const getQuestStatus = useSelector((state) => state.quest.getQuest.status);
  const subQuests = useSelector(
    (state) =>
      state.subquest.questSubQuests.find(
        (item) => item.id === courseQuest.quest.id
      )?.subQuests
  );

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const response = await getSubQuestsByQuestId(courseQuest.quest.id);
      if (response) {
        dispatch(
          subquestActions.addSubQuest({
            id: courseQuest.quest.id,
            subQuests: response.map((item, index) => ({
              id: index + 1,
              ...item,
            })),
          })
        );
      }
      setIsLoading(false);
    };

    fetchData();
  }, [courseQuest.quest.id, dispatch]);

  const editQuest = (editId) => {
    dispatch(questActions.setQuestEditMode(true));
    dispatch(fetchQuestByIdAction(editId));
  };

  const deleteQuest = (deleteId) => {
    dispatch(questActions.setQuestIdForDeletion(deleteId));
    dispatch(questActions.toggleDeleteModal(true));
  };

  const removeQuest = (questId) => {
    dispatch(
      questActions.setQuestIdForRemoval({
        courseId,
        questId,
      })
    );
    dispatch(questActions.toggleRemoveModal(true));
  };

  const getSubQuestById = (selectedId) =>
    subQuests.findIndex((item) => item.id === selectedId);

  const handleChangeOrderNo = (event) => {
    const { active, over } = event;

    if (active.id === over.id) return;

    const originalPos = getSubQuestById(active.id);
    const newPos = getSubQuestById(over.id);

    dispatch(
      updateSubQuestOrderNo({
        questId: courseQuest.quest.id,
        subQuestId: subQuests.find((subQuest) => subQuest.id === active.id)
          .subQuest.id,
        newOrderNo: newPos + 1,
      })
    );

    dispatch(
      subquestActions.arrangeOrderNo({
        questId: courseQuest.quest.id,
        subQuests: arrayMove(subQuests, originalPos, newPos),
      })
    );
  };

  return (
    <div ref={setNodeRef} style={style}>
      <Box mb={6} borderWidth="1px" borderRadius="md">
        <Box w="100%" p={4} bg={useColorModeValue('#f7f7f7', 'gray.800')}>
          <Flex align="center">
            <IconButton
              {...attributes}
              {...listeners}
              variant="plain"
              color="teal"
              aria-label="Re-order quest"
              icon={<DragHandleIcon />}
            />

            {isToggled ? (
              <IconButton
                onClick={() => setIsToggled(false)}
                variant="plain"
                aria-label="Untoggle quest"
                icon={<ArrowUpIcon />}
              />
            ) : (
              <IconButton
                onClick={() => setIsToggled(true)}
                variant="plain"
                aria-label="Toggle quest"
                icon={<ArrowDownIcon />}
              />
            )}

            <Text fontWeight={500} mx={2} fontSize="md" flex={1}>
              {courseQuest.quest.name}
            </Text>

            {courseQuest.quest.isPublished ? (
              <CheckCircleIcon color="green" />
            ) : (
              <RiDraftFill color="purple" />
            )}

            <AddSubQuestMd
              isEditable={courseQuest.isEditable}
              quest={courseQuest.quest}
            />

            <Menu>
              <MenuButton
                isLoading={getQuestStatus === ReducerStatus.LOADING}
                as={IconButton}
                aria-label="Options"
                icon={<HiDotsVertical />}
                variant="text"
              />
              <MenuList>
                <MenuItem
                  display={courseQuest.isEditable ? 'block' : 'none'}
                  icon={<EditIcon />}
                  onClick={() => {
                    editQuest(courseQuest.quest.id);
                  }}
                >
                  Edit
                </MenuItem>
                <MenuItem
                  icon={<GrDetach />}
                  onClick={() => {
                    removeQuest(courseQuest.quest.id);
                  }}
                >
                  Detach
                </MenuItem>
                <MenuItem
                  display={courseQuest.isEditable ? 'block' : 'none'}
                  icon={<DeleteIcon />}
                  onClick={() => {
                    deleteQuest(courseQuest.quest.id);
                  }}
                >
                  Remove
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </Box>

        <Collapse in={isToggled}>
          <Box p={4}>
            <Text mb={5}>{courseQuest.quest.description}</Text>

            {isLoading ? (
              <Stack>
                <Skeleton mb={6} height="72px" borderRadius="md" />
                <Skeleton mb={6} height="72px" borderRadius="md" />
              </Stack>
            ) : (
              <DndContext
                onDragEnd={handleChangeOrderNo}
                collisionDetection={closestCorners}
              >
                {subQuests && (
                  <SortableContext
                    items={subQuests}
                    strategy={verticalListSortingStrategy}
                  >
                    {subQuests.map((item) => (
                      <SubQuestAdminItem
                        key={item.id}
                        id={item.id}
                        questId={courseQuest.quest.id}
                        isEditable={item.isEditable}
                        subQuest={item.subQuest}
                      />
                    ))}
                  </SortableContext>
                )}
              </DndContext>
            )}
          </Box>
        </Collapse>
      </Box>
    </div>
  );
}

QuestAdminItem.propTypes = {
  courseId: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  courseQuest: PropTypes.object.isRequired,
};

export default QuestAdminItem;
